<template>
    <div class="mainNoFound">
        <img class="noFound" src="../../assets/images/no-found.png" alt="404-icon" title="404-icon" />
        <a class="backIndex" href="/">返回首页</a>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import eventBus from '../../plugins/eventBus';
  export default {
    name: 'index',
    mixins: [],
    props: {},
    model: {},
    components: {},
    data () {
      return {
        timing:null
      };
    },
    computed: {
      ...mapGetters(['loginUid', 'loginUser', 'redPoint','gameInfo'])
    },
    methods: {
      onShowLogin () {
        eventBus.$emit('onShowLogin');
      },
      timeChange(){
        this.timing = setTimeout(() => {
          if(window.location.href.indexOf('www') > 0){
            window.location.href = '/www';
          } else {
            window.location.href = '/entry';
          }
        },3000);
      }
    },
    watch: {},
    created () {

    },
    mounted(){
        this.timeChange();
    },
    updated () {

    },
    beforeDestroy () {
        clearTimeout(this.timing);
    },
    destroyed () {

    }
  };
</script>

<style lang="less" scoped>
    .mainNoFound{
        min-height:465px
    }
    .noFound{
        display:block;
        width:200px;
        margin:70px auto 0;
    }
    .backIndex{
        display:block;
        width:155px;
        height:40px;
        font-size:16px;
        color:#fff;
        text-align: center;
        line-height:40px;
        background:#00AFE8;
        border-radius: 4px;
        margin:20px auto 0;
    }
</style>
